.listItem {
  width: 100%;
  height: 450px;
}

.listItem img {
  height: 100%;
  object-fit: cover;
}

.listItem img:hover {
  scale: 1.05;
  transition: all 1s ease;
}

@media (max-width: 700px) {
  .listItem {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
