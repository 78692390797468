@media only screen and (max-width: 1245px) {
  /* Styles for screens smaller than 600px */
}

/* .actor-container {
  width: 90%;
  background-color: white;
  margin: 0px 5%;
  border-radius: 40px;
  max-width: 100%;
  height: auto;
} */

.actor-top-container {
  position: relative;
}

/* Media query for smaller screens (cell phones) */
@media screen and (max-width: 1245px) {
  .actor-top-container {
    position: relative;
  }
}

.actor-navbar {
  width: 90%;
  margin: 0px 5%;
}

.actor-play-icon {
  position: absolute;
  left: 50%;
  top: 70vh;
  cursor: pointer;
}

.actor-image-container {
  background-color: #1e0039;
  width: 100%;
  height: 80vh;
  background-size: cover;
  z-index: -1;
  display: block;
  object-fit: cover;
}

/* Adjust the z-index of other elements that you want to be above the image */
.actor-modal.is-open {
  z-index: 1001;
}

.shared-style {
  z-index: 1001;
}

#actor-image {
  width: px;
  height: 200px;
}

.actor-page-text-container {
  width: 300px;
}

.actor-profile {
  position: absolute;
  top: 42%;
  left: 8%;
  align-self: center;
  grid-column: 1/2;
  justify-self: center;
  /*background-image: url('../../../public/img1.png');*/
  background-repeat: no-repeat;
  background-size: cover;
  width: 30vw;
  height: 65vh;
  /*width: 360px;*/
  /*height: 480px;*/
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  align-items: end;
  z-index: 1000;
}

@media screen and (max-width: 1245px) {
  .actor-profile {
    width: 70vw;
    height: 45vh; /* Maintain the aspect ratio */
    max-width: 100%; /* Set the maximum width to 100% for smaller screens */
  }
}

@media screen and (max-width: 1245px) {
  .actor-profile {
    width: 70vw;
    height: 45vh; /* Maintain the aspect ratio */
    max-width: 100%; /* Set the maximum width to 100% for smaller screens */
  }
}

.arrow-actor-profile {
  font-size: 4rem;
  width: 50px;
  cursor: pointer;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border-radius: 10px;
}

.arrow-actor-profile2 {
  justify-self: end;
}

.arrow-actor-profile1 {
  justify-self: start;
}

/* Media query for smaller screens */
@media screen and (max-width: 1245px) {
  .arrow-actor-profile2,
  .arrow-actor-profile1 {
    width: 80%;
    height: 3rem;
  }
}

/* Media query for even smaller screens */
@media screen and (max-width: 1245px) {
  .arrow-actor-profile2,
  .arrow-actor-profile1 {
    width: 60%;
    height: 3rem;
  }
}

.actor-image-text {
  color: white;
}

.actor-middle-container {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(1, 10vh);
  justify-content: center;
  align-items: center;
  margin: 5px 40px 0px 40px;
  grid-column-gap: 20px;
}

/* Media query for smaller screens (cell phone) */
@media screen and (max-width: 1245px) {
  .actor-middle-container {
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(1, 10vh);
    display: flex;
    flex-direction: column; /* items vertically on smaller screens */
    align-items: center;
    justify-content: center;
    margin: 5px 100px 0px 100px; /* the margin for smaller screens */
    grid-column-gap: 20px;
  }
}

.Actor-Role.actor-detail-item {
  /* Default styles for larger screens */
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, auto);
  gap: 10px;
  justify-content: center;
  align-items: center; */

  /* Media query for smaller screens (cell phone) */
  @media screen and (max-width: 1245px) {
    display: flex;
    flex-direction: column; /* Display items vertically */
    align-items: center;
    margin: 5px 10px; /* the margin for smaller screens */
  }
}

.btn-follow,
.btn-Recommend,
.btn-star {
  height: 35px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  background-color: #712cb0;
  color: white;
  font-weight: bold;
  transition: 0.2s;
  width: 100%; /* Make the buttons full width */
  margin-bottom: 10px; /* spacing between buttons */
}

.btn-follow {
  grid-column: 5/6;
}

.btn-Recommend {
  grid-column: 9/10;
  width: 150px;
}

.btn-star {
  grid-column: 7/8;
}

.btn-star:hover,
.btn-Recommend:hover,
.btn-follow:hover {
  background-color: rgb(226, 226, 226);
  color: #1e0039;
  cursor: pointer;
}

.btn-Recommend:disabled,
.btn-follow:disabled {
  cursor: default;
  background-color: rgb(226, 226, 226);
  color: #1e0039;
}

.actor-movie-icon {
  width: 25px;
}

.follower-number {
  justify-self: start;
  margin-left: 5px;
}

.actor-icon-movie-container {
  display: flex;
  align-items: center;
  /* grid-column: 11/12; */
}

.actor-icon-movie-container button {
  margin-left: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.movie-number {
  margin-left: 5px;
  margin-right: 20px;
}

.bottom-container {
  margin-bottom: 30px;
  padding-bottom: 20%;
}

/* Media query for smaller screens */
@media screen and (max-width: 1245px) {
  .btn-follow,
  .btn-Recommend,
  .btn-star {
    width: 48%; /* Make the buttons take up 48% of the width to fit 2 by 2 */
  }

  .btn-star,
  .btn-follow,
  .btn-Recommend {
    margin-right: 4%; /* spacing between pairs of buttons */
  }

  .actor-icon-movie-container button {
    margin-left: 0%; /* Reset margin for buttons inside .actor-icon-movie-container */
  }
}

.bottom-actor-container-title {
  color: #1e0039;
  text-align: center;
  padding: 1em;
}

.movie-actor-play-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 2em;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: flex-start;
  margin: 0 1%;
  padding: 2% 2% 0 2%;
  background-color: rgba(30, 0, 57, 0.22);
  border-radius: 1em;
}

.movie-actor-play-container img {
  max-width: 350px;
  max-height: 400px;
  border-radius: 0%;
}

@media screen and (max-width: 1350px) {
  .movie-actor-play-container img {
    max-height: 350px;
  }
}

@media screen and (max-width: 1280px) {
  .movie-actor-play-container img {
    max-height: 280px;
  }
}

@media screen and (max-width: 1000px) {
  .movie-actor-play-container img {
    max-height: 220px;
  }
}

@media screen and (max-width: 780px) {
  .movie-actor-play-container img {
    max-height: 200px;
  }
}

.listItem p {
  margin-top: 10px;
  color: #1e0039;
  text-align: center;
  font-weight: bold;
  font-size: larger;
}

.movie-actor-play-container::-webkit-scrollbar {
  height: 1vh;
  width: fit-content;
}

.movie-actor-play-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.movie-actor-play-container::-webkit-scrollbar-thumb {
  background: #712cb0;
  border-radius: 10px;
}

.movie-number,
.follower-number,
.Actor-Role,
.follower-number-Recommend {
  color: #1e0039;
  font-weight: bold;
  font-size: 24px;
  margin-top: 4%;
}

.actor-city-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(1, 1fr);
  margin: 20px 0 35px 35px;
}

.actor-biography {
  box-shadow: 0px 0px 5px 2px #712cb0;
  grid-column: 6/ 9;
  width: auto;
  border-radius: 15px;
  padding: 15px;
  align-self: self-end;
  margin-right: -1px;
  margin-left: -120px;
  max-height: 230px;
  overflow: auto;
}

.actor-biography.scrollable {
  overflow-y: auto;
}

.actor-biography::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.actor-biography::-webkit-scrollbar-thumb {
  background: #712cb0;
  border-radius: 10px;
}

/* Default styles for desktop screens */
.actor-city-ethnicity {
  height: 100%;
  grid-column: 1/3;
  justify-self: center;
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
}

/* Media query for smaller screens (cell phones) */
@media only screen and (max-width: 880px) {
  .actor-city-ethnicity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /*  adjust the justification for smaller screens */
    margin-left: 10px; /* Adjust the margin for smaller screens */
  }
}

.actor-page-star {
  color: white;
  height: 30px;
  fill: white;
  display: inline;
}

.actor-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 500px;
  height: auto;
  max-height: 80vh;
  background-color: white;
  box-shadow: 1px 2px 9px #311465;
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
}

.actor-modal.is-open {
  display: block;
}

.shared-styles {
  height: 30px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: 1px 2px 9px #311465;
  text-align: left;
  font-size: 14px;
  padding-left: 10px;
}

.results-div {
  margin-top: 5px;
  padding: 5px;
  height: auto;
  min-height: 30px;
  max-height: 400px;
  overflow-y: auto;
}

.selected-filmmaker {
  display: block;
  padding: 3px 7px;
  margin: 5px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  background-color: #1e0039;
  box-shadow: 1px 2px 9px #311465;
  color: white;
  transition: 0.1s;
  cursor: pointer;
}

.selected-filmmaker-display {
  display: flex;
  justify-content: space-between;
  padding: 3px 7px;
  margin: 5px;
  margin-bottom: 10px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  background-color: white;
  box-shadow: 1px 2px 9px #311465;
  color: #1e0039;
  transition: 0.1s;
  cursor: pointer;
}

/* Media query for smaller screens */
@media screen and (max-width: 1245px) {
  .selected-filmmaker-display img {
    max-width: 80%; /* Decrease the maximum width for smaller screens */
  }
}

/* Media query for smaller screens */
@media screen and (max-width: 1245px) {
  .selected-filmmakers-display {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .selected-filmmaker-display {
    width: 100%;
    box-sizing: border-box;
    padding: 5px; /* Add some spacing around each filmmaker display */
  }

  .selected-filmmaker-display img {
    max-width: 100%; /* Make sure the image doesn't exceed the container width */
    height: auto; /* Allow the height to adjust proportionally */
    border-radius: 50%; /* Make it a circle */
  }
}

.btn-send {
  display: inline-block;
  height: 40px;
  padding: 0 15px;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: #1e0039;
  box-shadow: 1px 2px 9px #311465;
  transition: 0.2s;
  cursor: pointer;
  line-height: 35px;
  text-align: center;
}

/* Media query for smaller screens (cell phones) */
@media screen and (max-width: 1245px) {
  .text-purple-800.text-3xl.font-bold.ml-5 {
    margin-left: auto; /* Center the text horizontally on smaller screens */
    margin-right: auto;
    grid-column: span 12; /* Make the text span all 12 columns */
  }
}

/* Media query for smaller screens (cell phones) */
@media screen and (max-width: 1245px) {
  .grid-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px; /* Adjust the margin for smaller screens */
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(1, 1fr);
  margin: 20px 0 35px 35px;
  margin-top: 4%;
}
