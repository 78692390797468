.container {
  max-width: 120rem;
  margin: 0 auto;
  background-color: #170b3b;
}

.img {
  width: 80rem;
  height: 50rem;
  margin-bottom: 0.5rem;
  border-radius: 0.3rem;
  padding: 4rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.text {
  color: white;
  font-size: 2.4rem;
  line-height: 1.5;
  text-align: center;
  margin: 2rem 3rem;
}
