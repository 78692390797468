/* use compomonents way to style for login page
link for registration is /login */
.btn {
  background: #712cb0;
  color: white;
  border: 10px solid var(#712cb0);
  padding: 4px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(96, 35, 170, 0.2),
    0 6px 20px 0 rgba(175, 63, 227, 0.19);
  width: 200px;
  text-align: center;
}

.form {
  position: absolute;
  color: black;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form_input {
  background: white;
  width: 350px;
  border-radius: 10px;
  border: 10px solid var(#712cb0);
  font-size: 20px;
  font-weight: 300;
  outline-color: transparent;
  box-shadow: 0 0 0.4em #844fb0;
}

.formbody {
  text-align: center;
}

.bg {
  height: 100vh;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background: linear-gradient(
      rgba(238, 238, 238, 0.823),
      rgba(219, 219, 222, 0.856)
    ),
    url(../../../images/login/Curtains.png);
}

.link {
  text-align: center;
  color: #712cb0;
}

.form_title {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  color: #712cb0;
  padding: 50px;
}

.p {
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  padding: 100px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

img {
  display: block;
  margin: auto;
}

.error_text {
  color: red;
}

.form_Message {
  font-size: 20px;
  text-align: center;
  color: #1e0039;
}

input::selection {
  background-color: #712cb0;
  color: white;
}
