*{
   margin:0; 
   padding:0;
  
}
.container{
  max-width: 120rem;
  font-size: 62.5%;  
  font-weight: 400;
  color:#fff;
  box-sizing: border-box;
}

.hero {
  max-width: 130rem;
  background-image: url("../../../images/movies/imageSouthpaw.jpeg");
  background-repeat: none;
  background-size: cover;
  background-position: bottom;
  margin: 0 auto;
  position:relative;
}
/* .navContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding:.5rem .5rem;
}

.logo {
  width:3rem;
  height: auto;
}
.searchBar{

  padding-top: 0.5rem;
  display: flex;
  gap:.3rem;
}
 .searchTerm{
  width: 100%;
  height: auto;
  color: transparent;
  background-color: transparent;
  border:none;
  border-bottom: 1px solid #fff;
  font-size: 1rem;
  cursor: pointer;
   
}
.searchButton {
  width: 1rem;
  height: auto;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  align-self: flex-end;
}

.avatar {
  width:3rem;
 
  height: auto;
  border-radius: 50%;
} */

/* poster section */
.posterContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 3rem;
  padding-top:2rem;
  gap:2rem;
}

.img {
  width: 100%;
  height: auto;
  border-radius: 4rem;
  box-shadow: 5px 5px rgba(70, 9, 129, 0.7);
}

.description{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.centered {
  font-size: 3.6rem;
  letter-spacing: 3px;
  
}
.logline {
  font-size: 1.8rem;
  
}


/* corner section */

.flexContainer {
  width: 40%;
  display: flex;
  padding: 0;
  margin-top:3rem;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  margin-left:.5rem;
}

.el1 {
  color: rgb(225, 204, 11);
}
.el1,
.el2,
.el3 {
  font-size: 1rem;
  margin-bottom: 0;
}
.bottomeLine{
  display: flex;
  justify-content: space-between;
  position: relative;
 }
 /* .dropdownContainer{
  position: relative;
 }
  */
/* three dot section */
.threedots:after {
  cursor: pointer;
  color: #fff;
  content: '\2807';
  font-size: 20px;
  padding: 0 5px;
}

.dotAnkor {
  text-decoration: none;
  color: white;
}

.dotAnkor div {
  padding: 2px;
}

.dropdown {
  position: absolute;
  right: 10px;
  background-color: #66D;
  color:#fff;
  outline: none;
  opacity: 0;
  z-index: -1;
  max-height: 0;
  transition: opacity 0.1s, z-index 0.1s, max-height 5s;
}

.dropdownContainer:focus {
  outline: none;
}

.dropdownContainer:focus .dropdown {
  opacity: 1;
  z-index: 100;
  /* max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s, max-height 0.2s; */
}

/* icon-bar section */

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2d0353;
  width: auto;
  margin-top:.5rem;
}

.iconContainer a {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 2rem;
  padding: 0 1rem;
  display: inline-block;
}

/* Change the color of links on hover */
/* a:hover {
  background-color: #a263da;
  color: black;
}
a:active {
  color: white;
} */

.icon {
  font-size: 0.25rem;
  /* width: 1rem;
  height: 1rem; */
  background-color: #ddd;
}

/* 1rem =1em=16px */
/* below 1344px (smaller desktops) */

@media(max-width:84em){
   .hero{
    max-width: 120rem;
   
   }
   .logline {
    font-size: 1.6rem;
  }
  
}
/* below 1200px (landscape tablet) */

/* @media(max-width:75em){
  .container{
    font-size:56.25%;
  }
 
} */
/* below 998px (tablet) */

@media(max-width:62em){

  .hero{
    max-width: 100rem;
   
   }
   .centered {
    font-size: 2.4rem;
    letter-spacing: 3px;
    
  }
  .logline {
    font-size: 1.2rem;
  }
  .el1,
  .el2,
  .el3 {
    font-size: .8rem;
    margin-bottom: 0;
  }
  .flexContainer {
    width: 50%;
   
  }

  .posterContainer {
    display: flex;
    justify-content: space-between;
    margin:0 1.5rem;
    
    gap:1.5rem;
    
  }
  .img {
    width: 80%;
    height: auto;
    border-radius: 4rem;
    box-shadow: 5px 5px rgba(70, 9, 129, 0.7);
  }
  .description{
   
    align-items: flex-start;
    justify-content: space-between;
  }
 
}
/* below 768px (smaller tablet) */
@media(max-width:48em){
  .hero{
   max-width: 80rem;
  
  }
  .logline {
   font-size: 1rem;
 }
 
}

