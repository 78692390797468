.footer-container {
  display: flex;
  flex-direction: column;
}

.footer {
  flex-grow: 1;
}

.footer-links a {
  color: white;
  opacity: 0.15;
  text-decoration: none;
  font-size: 35px;
  padding: 0px 10px;
}

.footer-links a:hover {
  opacity: 1;
}

.footer-ul {
  list-style-type: none;
  margin-left: 2rem;
}

.footer-header {
  color: white;
}

.footer-li {
  color: white;
  opacity: 0.5;
  text-align: left;
}

.footer-li:hover {
  opacity: 1;
}

.footer-columns {
  margin: 1rem;
}
