.upload {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  padding: 0 1rem;
}

.uploadtext {
  font-size: 30px;
  text-align: center;
  color: #1e0039;
}
.uploadFilm {
  cursor: pointer;
}
.uploadFilm > img {
  width: 8rem;
  height: 8rem;
}
.uploadFilm > img:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}
.uploadNow {
  background-color: rgb(57, 4, 110);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 120px;
  cursor: pointer;
}
.howWork {
  background-color: rgb(57, 4, 110);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 120px;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .c-row {
    flex-direction: column;
    height: 100%;
  }

  .c-col {
    width: 280px;
    height: auto;
    flex: unset;
    margin: 20px 0;
  }

  .uploadtext {
    font-size: 24px;
    font-weight: 500;
  }

  .upload {
    flex-direction: column;
  }

  .uploadIcon {
    font-size: 10px;
  }
}

@media screen and (max-width: 900px) {
  .upload {
    flex-direction: column;
  }

  .uploadIcon {
    font-size: 12px;
  }
}
