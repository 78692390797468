.listContainer {
  display: flex;
  gap: 2rem;
  overflow-x: auto;
}

.listContainer img {
  max-width: 100vw;
  height: auto;
  object-fit: cover;
  flex: 0 0 auto;
}

.listContainer::-webkit-scrollbar {
  height: 1vh;
  width: fit-content;
  margin-top: -5%;
}

.listContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.listContainer::-webkit-scrollbar-thumb {
  background: #712cb0;
  border-radius: 10px;
}

.listItem {
  margin-bottom: -5vw;
}
