.container {
  max-width: 120rem;
  margin: 0 auto;
}

.stillcontainer {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: flex-start;
  column-gap: 1rem;
}
.el2 {
  grid-column: 2/-1;
}

.el3 {
  grid-column: 1/3;
  grid-row: 2/3;
}

.img1 {
  width: 50rem;
  height: 45vh;
  margin-bottom: 0.5rem;
  border-radius: 0%;
}

.img2 {
  width: 69rem;
  height: 45vh;
  margin-bottom: 0.5rem;
  border-radius: 0%;
}
.img3 {
  width: 69rem;
  height: 45vh;
  margin-bottom: 0.5rem;
  border-radius: 0%;
}
.img4 {
  width: 50rem;
  height: 45vh;
  margin-bottom: 0.5rem;
  border-radius: 0%;
}
