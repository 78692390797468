#home {
  background-size: cover;
  background-repeat: no-repeat;
}

.c-row1 {
  padding-top: 300px;
}

.movieTitle {
  color: white;
  text-align: center;
}

.movieIntro {
  color: white;
  text-align: center;
}

.homeHead-poster {
  border-radius: 55px;
  border: 2px solid #160625;
  height: 30vh;
  width: 20rem;
}

@media (max-width: 768px) {
  .homeHead-poster {
    height: 40vh;
    width: 15rem;
  }
}

@media (min-width: 992px) {
  .homeHead-poster {
    height: 50vh;
    width: 20rem;
  }
}

@media (min-width: 1200px) {
  .homeHead-poster {
    height: 60vh;
    width: 25rem;
  }
}
