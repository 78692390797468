/* use compomonents way to style for registration page
link for registration is /signup */
.bg {
  background-image: url(../../../images/login/VIP3.png);
  height: 100vh;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  z-index: -1;
}

.btn {
  background: #712cb0;
  color: white;
  border: 10px solid var(#712cb0);
  /* padding: 4px 50px; */
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(96, 35, 170, 0.2),
    0 6px 20px 0 rgba(175, 63, 227, 0.19);
  width: 200px;
  text-align: center;
  /* margin-left: 150px; */
  z-index: 1;
}
