.listItemactor {
  position: relative;
  width: 236px;
  height: 285px;
  margin: 15px;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s;
  /* Increase the transition duration for smoother effect */
}

.actor-name {
  color: white;
  align-items: center;
  text-align: center;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 90;
  line-height: 28px;
  letter-spacing: 0em;
  z-index: 2;
}

.actorname {
  color: #1E0039;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Roboto;
  font-size: 30px;
  font-weight: 90;
  line-height: 28px;
  letter-spacing: 0em;
  opacity: 0;
  /* Initially hidden */
  z-index: 2;
  text-align: center;
  border-radius: 0 0 5% 5%;
  width: 100%;
  /* Ensure the name covers the full width of the container */
  background: linear-gradient(180deg, #FFFFFF 10%, rgba(255, 255, 255, 0) 100%);
  padding: 5px;
  /* Add padding for better visibility */
  transition: opacity 0.5s;
  /* Add opacity transition for smooth hover effect */
}

.listItemactor .actor-image {
  width: 90%;
  height: 90%;
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  /* Ensure the image is above other content */
  border-radius: 5%;
  transition: opacity 0.5s;

}

.listItemactor:hover {
  /* margin-top: 5px;
  margin-bottom: 5px; */
  /* margin-left: 90px;
  margin-right: 90px; */
  scale: 1.18;
  transition: all 1s ease;
  border-radius: 10%;
  opacity: 1;
  z-index: 2;
}

.listItemactor:hover .actor-image {
  opacity: 1;
}

.listItemactor:hover .actorname {
  opacity: 1;
}

.listItemactor:hover .actor-name {
  opacity: 0;
  font-size: 1px;
  font-weight: 1;
  line-height: 1px;
}