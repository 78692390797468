.switch-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e0039;
  padding: 2rem 0;
}

.switch-btn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  width: 1512px;
  height: 28px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.247);
  background-color: white;
}

.switch-btn button {
  background-color: white;
  border: none;
  color: black;
  text-align: center;
  /* font-size: 32px; */
  font-size: 18px;
  width: 100%;
  /* height: 52px; */
  height: 28px;
  cursor: pointer;
  border-radius: 30px;
}

#epk-btn {
  grid-column: 1/2;
}

#actor-btn {
  grid-column: 2/3;
}

.switch-btn button.active {
  background-color: #712cb0;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.247);
}
