.container {
  max-width: 120rem;
  margin: 0 auto;
  margin-top: 2rem;
}

.resourcesCard {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d2ccd6;
  border-radius: 25px;
  gap: 20rem;
}
.img {
  width: 35rem;
  height: 30rem;
  margin-bottom: 0.5rem;
  border-radius: 0.3rem;
  padding: 4rem;
}
.text {
  color: white;
  font-size: 2.4rem;
  line-height: 1.5;
  text-align: center;
  margin: 2rem 3rem;
}

/* .resourcesCard:nth-child(4n+3), .list:nth-child(4n+4){
  display: inline-block;
  width: 50px;
} */

.icon {
  font-size: 1.6rem;
}
.icon a:hover {
  background-color: #a263da;
  background-size: contain;

  height: 5rem;
  color: black;
}
.icon a:active {
  color: white;
}
