/* *{
    margin:0; 
    padding:0;
   
 } */
.wholeContainer {
  font-size: 62.5%;
  font-weight: 400;
  color: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #1e0039;
  max-width: 120rem;
  height: auto;
  padding: 0 3rem;
}
.socialMedia {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 2rem;
  font-weight: 600;
  font-size: 2rem;
  gap: 5rem;
}
.iconAmount {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.peopleIcon {
  filter: invert(1);
  width: 4rem;
  height: 4.5rem;
}

.totalNumber {
  color: #fff;
  font-size: 2rem;
}

.hero {
  margin-top: 1rem;
  background-repeat: none;
  width: 100%;
  height: 75vh;
  background-size: cover;
  background-position: 20% 10%;
  position: relative;
  box-shadow: -5px 20px 12px -9px rgba(242, 229, 229, 0.94) inset;
}

/* poster section */
.posterContainer {
  display: flex;
  padding: 3rem;
  justify-content: flex-start;
  gap: 5rem;
  margin-left: auto;
  margin-right: auto;
}

.imgPoster {
  min-width: 20vw;
  min-height: 50vh;
  border-radius: 4rem;
  box-shadow: 5px 5px rgba(70, 9, 129, 0.7);
}

.description {
  flex-basis: 80rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.centered {
  font-size: 3.6rem;
  letter-spacing: 3px;
}

/* corner section */

.flexContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10rem;
  color: #fff;
  padding-left: 0.5rem;
}

.flexContainer h2 {
  font-size: 1.4rem;
  font-weight: 500;
}
.textLeft {
  flex-basis: 15rem;
}

.pre {
  color: rgb(225, 204, 11);
}
.pre,
.genre,
.date {
  font-size: 1rem;
}
.logline {
  font-size: 1.2rem;
}

/* report section */

.reportForm {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  transform: translate(-150%, -125%);
  min-height: 20rem;
  min-width: 30rem;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 10;
  color: #000;
}
.closeBtn {
  color: #000;
  font-size: 2rem;
  top: 0;
  right: 1rem;
  position: absolute;
  cursor: pointer;
}
.reportTitle {
  font-size: 1.2rem;
  padding: 2rem;
  text-align: center;
}
.hidden {
  display: none;
}

.form1 {
  width: 80%;
  transform: translate(10%, -10%);
  /* position: absolute; */
}
.form1Input {
  position: relative;
  width: 100%;
  color: #000;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
}
.selected {
  position: relative;
  width: 100%;
  background-color: #1e0039;
  color: #fff;
  margin-bottom: 0.5rem;
  border-radius: 5px;
}
.form1 label {
  font-size: 1rem;
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  z-index: 10;
}
.submitReport {
  color: red;
  font-size: 1.4rem;
  transform: translate(65%, -40%);
  position: absolute;
  bottom: -30%;
  width: 10rem;
  border-radius: 5px;
  box-shadow: 2px 3px #a263da;
}

.inputContainer {
  position: relative;
}
.infoIcon {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  font-size: 1.5rem;
  font-weight: lighter;
  cursor: pointer;
}
.infoIconSelect {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  font-size: 1.5rem;
  font-weight: lighter;
  cursor: pointer;
  color: white;
}
.comment {
  padding-left: 4rem;
  width: 100%;
  margin-bottom: 0.5rem;
  border-radius: 5px;
}

.dotSection {
  /* position: absolute; */
  bottom: 1rem;
  right: 1rem;
  color: #170b3b;
  font-size: 1.8rem;
}

.reportSection {
  /* position: absolute; */
  bottom: 0;
  right: 0;
}
.reportBtn {
  color: red;
  padding: 0.5rem 0rem 0.5rem 0rem;
  font-size: 1.4rem;
  background-color: rgba(255, 255, 255, 0);
}
.reportMessage {
  position: absolute;
  left: 10%;
  top:90%;
  background-color:#170b3b;

  transform: translate(150%, -40%);
  min-height: 5rem;
  max-width: 15rem;

  border-radius: 5px;
  z-index: 10;
  color: #fff;
  font-size: 1rem;
  padding: 10px;

}
.reportP {
  padding: 6rem;
  font-size: 1.6rem;
}
/* icon-bar section */

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e0039;
  position: relative;
  font-size: 2rem;
  padding: 1rem 1rem;
}
.iconContainer span {
  position: absolute;
  top: 0;
  font-size: 1.2rem;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
}

/* details section */

.detailContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10rem;
  background-color: #fff;
  color: #000;
  padding: 2rem;
}

.imgDetail {
  height: 50vh;
  width: 20vw;
  border-radius: 4rem;
  box-shadow: 5px 5px rgba(70, 9, 129, 0.7);
}

.middle p {
  line-height: 1.8;
  font-size: 1.8rem;
}
.leftDetail p {
  line-height: 1.8;
  font-size: 1.8rem;
  text-align: center;
}

/* logline section */
.loglineContainer {
  background-color: #fff;
  padding: 2rem;
}

.loglineContainer p {
  font-size: 2.4rem;
  font-weight: 700;
  color: rgb(72, 8, 8);
  margin-left: 25%;
  margin-right: 25%;
  text-align: center;
  padding-bottom: 1rem;
}
.imgLogline {
  min-height: 40vh;
  min-width: 50%;
  border-radius: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 5px 5px rgba(70, 9, 129, 0.7);
}

/* synopsis section */

.synopsis {
  position: relative;
  margin-bottom: 1rem;
  padding-top: 1rem;
}

.position {
  position: absolute;
  top: 4rem;
  right: 7rem;
}

.position1 {
  position: absolute;
  top: 207rem;
  right: 7rem;
}

.content {
  position: relative; 
  text-align: center;
  color: white;
  font-size: 2.4rem;
}
.content1 {
  filter: blur(8px);
}

.type {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background-color: #1e0039;
  padding: 1rem;
}

.btnSy {
  position: absolute;
  top: 0.5rem;
  right: 32rem;
  transform: translate(-50%, 10%);
  width: 10rem;
  height: auto;
  font-size: 1rem;
  border-radius: 8px;
  box-shadow: 2px 3px #a263da;
  color: #000;
  background-color: #fff;
  font-weight: 500;
  z-index: 2;
}
.imgSynopsis {
  padding: 1rem;
  min-height: 70vh;
  min-width: 90vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* aspect-ratio: 1/ 2; */
  border-radius: 3rem;
}

.text {
  text-align: center;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
}
.none {
  display: none;
}

/* uniqueness section */
.unique {
  position: relative;
  background-color: #fff;
  padding: 2rem;
}
.titleUnique {
  font-size: 3.6rem;
  font-weight: 700;
  color: #000;
  text-align: center;
}
.uniqueContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 10rem;
}
.btnUni {
  position: absolute;
  top: -206rem;
  left: 1rem;
  transform: translate(-50%, 10%);
  width: 10rem;
  height: auto;
  font-size: 1rem;
  border-radius: 8px;
  box-shadow: 2px 3px #a263da;
  color: #000;
  background-color: #fff;
  font-weight: 500;

  z-index: 2;
}
.imgUnique {
  height: 40vh;
  width: 100vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.textUnique {
  font-size: 2.4rem;
  color: #000;
  padding: 0 3rem;
  text-align: center;
}

/* starring/cast section */

.starring {
  padding: 0 2rem;
  background-color: #fff;
  margin-top: 1rem;
}
.starTitle {
  font-size: 3.6rem;
  color: #000;
  margin-top: 2rem;
  font-weight: 700;
  text-align: center;
}
.starcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 5rem;
  gap: 40rem;
}

h1 {
  color: #1e0039;
  font-weight: 700;
  font-size: 1.8rem;
  text-align: center;
  margin: 0;
}
.biography {
  color: #1e0039;
  font-size: 2rem;
  line-height: 1.5;
  padding: 0 0rem;
  text-align: center;
}
.imgLeft {
  flex-basis: 100%;
  justify-self: center;
  align-self: center;
}
.starimg {
  min-width: 15vw;
  min-height: 40vh;
  aspect-ratio: auto;
  border-radius: 2rem;
  box-shadow: 5px 5px rgba(70, 9, 129, 0.7);
}
.mediaIcon {
  text-align: center;
}

/* Director/proudcer section */
.directorcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e0039;
  gap: 40rem;
  padding: 3rem 6rem;
  text-align: center;
}
.producerimg {
  min-width: 15vw;
  min-height: 40vh;
  aspect-ratio: auto;
  border-radius: 2rem;
  box-shadow: 3px 3px rgb(237, 235, 240);
}

.left {
  flex-basis: 100%;
}
.right {
  align-self: flex-start;
}
.left h1 {
  color: white;
  font-weight: 500;
  font-size: 1.8rem;
  text-align: center;
}
.right p {
  color: white;
  font-size: 2rem;
  line-height: 1.5;
}
.right h3 {
  text-align: center;
  font-size: 3.6rem;
}

/* stills section */
.stills {
  position: relative;
  padding: 3rem;
  background-color: #fff;
}
.stillsContainer {
  display: flex;
  align-items: center;
  justify-items: flex-start;
  column-gap: 1rem;
  margin-bottom: 1rem;
}
.btnStills {
  position: absolute;
  top: -206rem;
  left: 1rem;
  transform: translate(-50%, 10%);
  width: 10rem;
  height: auto;
  font-size: 1rem;
  border-radius: 8px;
  box-shadow: 2px 3px #a263da;
  color: #000;
  background-color: #fff;
  font-weight: 500;
}
.imgStillsLeft {
  min-width: 40vw;
  min-height: 50vh;
  aspect-ratio: 1 / 2;
}
.imgStillsRight {
  min-width: 45vw;
  min-height: 50vh;
  aspect-ratio: 1/ 2;
}

/* resources section */

.resource {
  padding: 3rem;
  background-color: #fff;
  margin-top: 1rem;
}
.resourcesCard {
  display: flex;
  margin: 40px 0;
  align-items: center;
  justify-content: flex-start;
  background-color: #d2ccd6;
  border-radius: 30px;
  gap: 15rem;
}
.imgResource {
  min-width: 30vw;
  min-height: 50vh;
  aspect-ratio: auto;
  margin-bottom: 0.5rem;
  border-radius: 6rem;
  padding: 4rem;
}
.textResource {
  color: rgb(23, 11, 59);
  font-size: 2.4rem;
  text-align: center;
  margin: 2rem 3rem;
  align-self: flex-start;
}

.textResource h1 {
  font-size: 3.6rem;
}

/* trailer section */

video {
  padding-top: 2rem;
  width: 100vw;
  height: auto;

  border-radius: 2rem;
}

/* award section */
.awardContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 3rem;
  background-color: #fff;
  gap: 5rem;
  height: auto;
}
.award {
  color: #000;
  font-size: 1.8rem;
  text-align: start;
  flex-basis: 50rem;
}
.imgAward {
  width: 20vw;
  height: 30vh;
}

/* 1rem =1em=16px */
/* below 1344px (smaller desktops) */

@media (max-width: 84em) {
  .hero {
    max-width: 120rem;
  }
  .logline {
    font-size: 1.6rem;
  }
}
/* below 1200px (landscape tablet) */

/* @media(max-width:75em){
   .container{
     font-size:56.25%;
   }
  
 } */
/* below 998px (tablet) */

@media (max-width: 62em) {
  .hero {
    max-width: 100rem;
  }
  .centered {
    font-size: 2.4rem;
    letter-spacing: 3px;
  }
  .logline {
    font-size: 1.2rem;
  }
  .el1,
  .el2,
  .el3 {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  .flexContainer {
    width: 50%;
  }

  .posterContainer {
    display: flex;
    justify-content: space-between;
    margin: 0 1.5rem;

    gap: 1.5rem;
  }
  .img {
    width: 80%;
    height: auto;
    border-radius: 4rem;
    box-shadow: 5px 5px rgba(70, 9, 129, 0.7);
  }
  .description {
    align-items: flex-start;
    justify-content: space-between;
  }
}
/* below 768px (smaller tablet) */
@media (max-width: 48em) {
  .hero {
    max-width: 80rem;
  }
  .logline {
    font-size: 1rem;
  }
}
