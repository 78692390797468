.toggle {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition-duration: 0.5s;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: rgb(176, 92, 255);
  border-radius: 4px;
}

#bar2 {
  transition-duration: 0.8s;
}

#bar1,
#bar3 {
  width: 70%;
}

.activeNavItem .bars {
  position: absolute;
  transition-duration: 0.5s;
}

.activeNavItem #bar2 {
  transform: scaleX(0);
  transition-duration: 0.5s;
}

.activeNavItem #bar1 {
  width: 100%;
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

.activeNavItem #bar3 {
  width: 100%;
  transform: rotate(-45deg);
  transition-duration: 0.5s;
}

.activeNavItem {
  transition-duration: 0.5s;
  transform: rotate(180deg);
}
