@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";

// Magic numbers
$magic-horizontal-value: 3.2rem;
$magic-vertical-value: 5rem;
$sideways-tabs-height: 1rem;

// Placeholders
%sideways-tabs {
  margin-top: $magic-vertical-value;
  border: none;
  position: relative;
  margin-bottom: 0;
}

// Used in vertical tab configuration (wide view)
%flex-tabs {
  height: 100%;
  flex-direction: column;
}

// Mixins (can be reused inside @media scope)
@mixin fixed-tab($size: $fixed-tab-size) {
  width: $size;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Used in horizontal tab configuration (narrow views)
@mixin flex-tabs-h {
  flex-direction: row;
  border-right: none;
  border-left: none;
  min-width: 100%;
}

.tab-clickable {
  cursor: pointer;
}

// Left vertical tabs
.left-tabs.nav-tabs {
  @extend %flex-tabs;
  border-right: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-bottom: none;
}

.left-tabs .nav-link {
  border-top-left-radius: $nav-tabs-border-radius;
  border-bottom-left-radius: $nav-tabs-border-radius;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: -$nav-tabs-border-width;
  margin-bottom: 2rem;
  font-size: 20px;
}

.left-tabs .nav-link:hover {
  border-right: $nav-tabs-border-width solid transparent;
}

.left-tabs .nav-link.active {
  border-top: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-right: $nav-tabs-border-width solid transparent;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
}

.left-panel {
  background-color: #591398;
  color: white;
  height: 100%;
  margin-right: -1rem;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.left-heading {
  margin-left: 5rem;
}

.acc-name {
  padding-top: 2rem;
}

.right-panel {
  background-color: black;
  height: 33em;
  border: 3px solid #591398;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  width: 50%;
}

.form-box {
  width: 50px;
}

.edit-button {
  background-color: #591398;
  color: white;
  margin-top: 4rem;
}

.form-check-label {
  color: white;
}

.form-check-input {
  background-color: #591398;
  border: solid 1px white;
}

.news {
  color: white;
  display: inline-block;
}

.del-acc {
  margin-top: 10rem;
  color: white;
}

.top-space {
  margin-top: 7rem;
}

.dropdown-toggle {
  background-color: black;
  color: white;
  border: 1px solid white;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #591398;
}

input:focus + .slider {
  box-shadow: 0 0 1px #591398;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.billing {
  color: white;
}

.table-img {
  height: 3rem;
}
.hint {
  color: black;
  font-weight: 500;
}
// new snoppet setting design

.edit-card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.edit-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.edit-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}

.gutters-sm > .col,
.gutters-sm > [class*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.h-100 {
  height: 100% !important;
}
.shadow-none {
  box-shadow: none !important;
}

.white-text {
  color: #fff;
}