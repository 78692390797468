/* Dropdown.css */

.filter-button-container {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  /* position: absolute; */
  display: block;
  border: 1px solid #ccc;
  background-color: white;
  /* z-index: 1; */
}

.dropdown-content .dropdown-option {
  background-color: white;
  color: #1e0039;
}

/* .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
} */

.dropdown-content div {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.dropdown-content .selected {
  background-color: white !important;
}
