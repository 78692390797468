.container {
  max-width: 120rem;
  margin: 0 auto;
}

.directorcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #170b3b;
  gap: 20rem;
  padding: 3rem 3rem;
}
.img {
  width: 25rem;
  height: 35rem;
  border-radius: 2rem;
  box-shadow: 3px 3px rgb(237, 235, 240);
}

.el1 {
  justify-self: center;
  align-self: center;
}
.el2 {
  align-self: center;
  justify-self: center;
}
h1 {
  color: white;
  font-weight: 500;
  font-size: 3.6rem;
}
.el2 p {
  color: white;
  font-size: 2.4rem;
  line-height: 1.5;
}
