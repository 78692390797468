.container{
  max-width: 120rem;
  margin: 0 auto;
  font-size: 62.5%;  
  font-weight: 400;
  color:#000;
  box-sizing: border-box;
}

.detailContainer {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:3rem;
}

.img {
  height: 80%;
  width: auto;
  border-radius: 4rem;
}

.el2 {
  line-height: 1.5;
  font-size: 1.8rem;
  
  
}
.el3{ 
  line-height: 1.5;
  font-size: 1.8rem;
 text-align: center;
}



